import React from 'react'
import Img from 'gatsby-image'

import './heroBgImg.css'

const HeroBgImg = ({ children, imgUrl }) => (
  <div style={{ marginTop: "-1.45rem" }}>
    <div style={{
        backgroundColor: 'rgba(0,0,0,0.4)',
      }}
      className="hero-bg-img"
    >
      {children}
      <Img
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          zIndex: -1
        }}
        fluid={imgUrl}
      />
    </div>
    <div className="hero-bg-img-spacer"></div>
  </div>
)

export default HeroBgImg



