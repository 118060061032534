import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import HeroBgImg from '../components/heroBgImg'

const AboutPage = ({ data }) => (
  <Layout>
    
    <Helmet>
      <title>AlphaDex | About Us</title>
      <meta name="description" content="AlphaDex is your go to spot for finding the best tools and resources for selling on amazon. Get to know a little about our background and our mission." />
      <meta name="keywords" content="amazon, selling, about" />
    </Helmet>

    <HeroBgImg imgUrl={data.aboutBG.childImageSharp.fluid}>
      <h1 style={{textAlign: 'center'}}>About Us</h1>
    </HeroBgImg>
    
    <h2 style={{paddingTop: 20}}>Who we are</h2>
    <p>Everyone has a favorite software, podcast or tool they use, but why?  What makes each one different?  Being an Amazon consultant, I have worked with countless software and agencies throughout the years.  With a hands on management style, my team and I believe in using a different set of tools for every client and educating them along the way.  With the Alphadex we want to inform sellers of everything that is available to them and help them choose the best options for their business.</p>
    <h2>Contact Us</h2>
    <p>If you'd like to get in touch, send us an email at <a href="mailto:info@thealphadex.com">info@thealphadex.com</a></p>
    <h2>Issues with the site?</h2>
    <p>If you find a bug, or see some incorrect data, or just have a suggestion for the site, you can either send us an email at <a href="mailto:info@thealphadex.com">info@thealphadex.com</a>, or create an issue directly on <a href="https://bitbucket.org/baumant/alphadex/issues/new" target="_blank" rel="noopener noreferrer">bitbucket</a> and we'll take care of it asap.</p>
  </Layout>
)

export const query = graphql`
  {
    aboutBG: file(relativePath: {eq: "about.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default AboutPage
